import { Link, graphql } from 'gatsby';

import Breadcrumbs from '@components/common/Breadcrumbs';
import Grid from '@components/common/Grid';
import Image from '@components/common/Image';
import OrganisedDetails from '@components/OrganisedDetails';
import React from 'react';
import SEO from '@components/common/SEO';
import SanitizedContent from '@components/common/SanitizedContent';
import { labels } from '@src/constants';
import parse from 'html-react-parser';
import { renderBlocks } from '@src/helpers';

// set up breadcrumbs

const Location = ({ pageContext, data }) => {
  const { language: lang, id } = pageContext;
  const {
    seo,
    title,
    featuredImage,
    ACFSede: { longTitle, address, contacts, openingTimes, contents },
  } = data.wpLocation;

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        <Grid className="col-span-12 bg-white py-12 lg:sticky lg:top-48 lg:z-9">
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>
              <span className="block">{labels.location[lang]}:</span>
              <span className="block">{title}</span>
            </p>
            {/* <Breadcrumbs current={title} parent={'locations'} /> */}
          </OrganisedDetails>
          {address && (
            <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
              <p>
                <span className="block">{labels.address[lang]}:</span>
                <span className="block">{parse(address)}</span>
              </p>
            </OrganisedDetails>
          )}
          {openingTimes && openingTimes.length > 0 && (
            <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
              <p>
                <span className="block">{labels.times[lang]}:</span>
                <span className="block">
                  {openingTimes.map((time, i) => (
                    <span key={i} className="flex flex-wrap justify-start items-baseline">
                      <span className="flex-20 min-w-100">{time.days}</span>
                      <span className="flex-2-auto">{time.times}</span>
                    </span>
                  ))}
                </span>
              </p>
            </OrganisedDetails>
          )}
          {contacts && (
            <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
              <div>
                <span className="block">{labels.contacts[lang]}:</span>
                <div className="block">
                  <SanitizedContent copy={contacts} />
                </div>
              </div>
            </OrganisedDetails>
          )}
        </Grid>
        <h1 className="col-span-12 text-md sm:text-lg tracking-headline">{title}</h1>
        {/* {featuredImage && (
          <section className="col-span-12 text-center">
            <Image image={featuredImage} />
          </section>
        )} */}
        {renderBlocks(contents)}
      </Grid>
    </>
  );
};

Location.displayName = 'Location';

export const data = graphql`
  query($id: String) {
    wpLocation(id: { eq: $id }) {
      title
      ACFSede {
        address
        contacts
        longTitle
        openingTimes {
          days
          fieldGroupName
          times
        }
        contents {
          ... on WpLocation_Acfsede_Contents_Image {
            fieldGroupName
            borderBottom
            image {
              altText
              caption
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
          ... on WpLocation_Acfsede_Contents_Copy {
            copy
            title
            fieldGroupName
            paddingBottom
          }
          ... on WpLocation_Acfsede_Contents_Video {
            borderBottom
            embedSource
            source
            fieldGroupName
            autoplay
            hideControls
            mute
            loop
            linkSource {
              sourceUrl
            }
          }
          ... on WpLocation_Acfsede_Contents_MediaGrid {
            columns
            borderBottom
            fieldGroupName
            media {
              embedSource
              mediaType
              source
              linkSource {
                sourceUrl
              }
              image {
                altText
                caption
                mediaDetails {
                  width
                  height
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          caption
          mediaDetails {
            width
            height
            sizes {
              height
              name
              sourceUrl
              width
            }
          }
          mediaItemUrl
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`;

export default Location;
